import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetContactsQuery } from "../../Api/api";
import "./Email.css";

const Email = () => {
  const form = useRef();
  const { data: contacts, isFetching } = useGetContactsQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    try {
      setIsSubmitting(true);

      // Send a POST request to your Django API
      const response = await axios.post(
        "https://backend-prashant-portfolio.vercel.app/api/send-email/",
        formData
      );

      if (response.status === 200) {
        toast.success("Email sent successfully");
        e.target.reset();
      } else {
        toast.error("Error sending email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [contactsDetails, setContactDetails] = useState(contacts);
  // const img_300 = "http://127.0.0.1:8000";
  useEffect(() => {
    setContactDetails(contacts);
  }, [contactsDetails, contacts]);
  if (isFetching) return "loading";
  return (
    <>
      <div className="reachme-container" id="contact">
        <div className="reachme-title2">
          <h2>I Want To Hear From You</h2>
          <h3>Contact Me</h3>
        </div>
        <div className="row">
          <div className="col-md-5  ">
            <div className="reachme-title">
              <div className="row">
                {contactsDetails &&
                  contactsDetails.map((details) => (
                    <div className="contact-info  " key={details.id}>
                      <div className="contact-details">
                        <i className={details.icon}></i>
                        <div className="contact-mi">
                          <h4 className="icon-name">{details.contact_name}:</h4>
                          <p className="d-name">{details.contact_info}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6 email-me container">
            <form
              action=""
              className="contact-form"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row">
                <div className="col-md-12 mb-3 hire-me-title"></div>
                <div className="col-md-6 ">
                  <input
                    type="text"
                    name="user_name"
                    id=""
                    placeholder="Enter Your Name"
                    required // Add the required attribute
                  />
                </div>
                <div className="col-md-6 ">
                  <input
                    type="email"
                    name="user_email"
                    id=""
                    placeholder="Enter Your Email"
                    required // Add the required attribute
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    name="subject"
                    id=""
                    placeholder="Enter Subject"
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <textarea
                    name="message"
                    id=""
                    cols="60"
                    rows="8"
                    placeholder="Your Message"
                  ></textarea>
                  <button
                    className="hire-btn"
                    type="submit"
                    disabled={isSubmitting} // Disable the button during submission
                  >
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Email;
